import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404 Page not found" />
    <section className="page_not_found pad_btm">
      <div className="pnf_content">
        <h2>Page not found</h2>
        <p className="black-dash">
          The page that you are looking for is not here, lets take you{" "}
          <Link to="/">back to home.</Link>
        </p>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
